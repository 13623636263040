/*------------------------------------------------------------*\
# BASIC THEME COLORS
\*------------------------------------------------------------*/
$color-primary: #7986cb;
$color-primary--transparent: #7986cb59;

$color-shopana-default: #090574;
$color-shopana-default-shade: #690000;

$color-white: #fff;
$color-black: #000;
$color-off-white: #fafbfb14;
$color-white--transparent: #fafbfb14;

$color-editor-grey: #ccc;
$color-off-grey: #e9e9e9;
$color-silver: #d6d8d9;
$color-charcoal: #2a3233;
$color-cool-grey: #a5adaf;
$color-gunmetal: #44565a;
$color-transparent-grey: #f7f8fa;
$color-maize: #f9c747;
$color-maize-transparent: #f9c74724;
$color-jade-green: #28b667;
$color-jade-green-dark: #40b149;
$color-jade-green-transparent: #28b66724;
$color-green-hover: #3ebd76;
$color-faded-red: #e34c4c;
$color-red: #e1464e;
$color-table-row-bg: #f8f8f8;
$color-orange-peel: #f59f00;
$color-luminous-grey: #dbdcdd;

$color-vivid-blue: #0178aa;
$color-vivid-blue-dark: #175fe4;
$color-msg-blue: #3970b0;
$color-bg-note: #ebeff0;
$color-navbar-selected: rgba(240, 240, 240, 0.1);
$color-off-green: #e6efea;

$color-alto: #dfdfdf;
$color-light-green: #f1fff1;

/*------------------------------------------------------------*\
# TEXT COLORS
\*------------------------------------------------------------*/
$color-text-default: #2a3233;
$color-text-light: #a5adaf;
$color-text-medium: #44565a;
$color-text-dark: #000000;
$color-text-chart-legend: #758184;

/*------------------------------------------------------------*\
# SHADOWS
\*------------------------------------------------------------*/
$color-shadow-default: $color-cool-grey;
$color-shadow-light: $color-off-white;
$color-shadow-medium: $color-silver;

/*------------------------------------------------------------*\
# BORDERS
\*------------------------------------------------------------*/
$color-border-default: #e8e8e8;
$color-border-light: $color-off-grey;
$color-border-medium: $color-silver;

/*------------------------------------------------------------*\
# LOADER SHIMMER
\*------------------------------------------------------------*/
$color-shimmer-low: $color-off-grey;
$color-shimmer-high: $color-white--transparent;
