.img-product {
    max-height: 100%;
    max-width: 100%;
    max-height: 100px;
    min-height: 70px;
    display: block;
    margin: auto;
    border-radius: 2px;
}

.styled-card {
    max-height: 116px;
}

.styled-link {
    color: #777777;
    text-decoration: none !important;
    &:hover {
        color: #000
    }
}

.header-color {
    background: #f8f8f8;
}

.no-img-product {
    min-height: 110px;
}

.order-card {
    border-radius: 0px !important;
    box-shadow: none !important;
    border: none;
}

.orderlist-container{
    box-shadow: 0px 1px 2px #b4b4b4;
}

@media only screen and (max-width: 768px) {
    .no-img-product {
        min-height: 70px;
    }

    .styled-link {
        font-size: 12px !important;
    }
  }
