@import "./colors";
@import "./defaults";
@import "./mixins";
@import "./typography";
@import "../fonts/icomoon/style.css";
@import "../fonts/Montserrat/style.css";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
body,
html,
#root,
.bootstrap {
  height: 100%;
  width: 100%;
  font-family: $font-family-base !important;
  -webkit-overflow-scrolling: touch;
}

/*------------------------------------------------------------*\
# APPLICATION LAYOUT STYLES
\*------------------------------------------------------------*/
.app-layout {
  min-height: 100vh;
}
