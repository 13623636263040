/*------------------------------------------------------------*\
# MIXINS FOR RWD SUPPORT
\*------------------------------------------------------------*/
$phone-min-width: 320px;
$phone-max-width: 599px;
$tablet-min-width: 600px;
$tablet-max-width: 1024px;
$tablet-max-width: 1024px;
$desktop-min-width: 1024px;
$desktop-max-width: 1024px;

@mixin phone {
  @media (min-width: #{$phone-min-width}) and (max-width: #{$phone-max-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-min-width}) and (max-width: #{$desktop-min-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-min-width}) {
    @content;
  }
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
/*====================| Usage |/*====================|*/
/*
@include phone {
  .class-name {
    // styles
  }
}

@include tablet {
  .class-name {
    // styles
  }
}

@include desktop {
}
*/

$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

@mixin xs {
  @media (min-width:0) and (max-width: #{$xs - 1px}) {
    @content;
  }
}
@mixin sm {
  @media (min-width:$xs) and (max-width: #{$sm - 1px}) {
    @content;
  }
}
@mixin md {
  @media (min-width:$sm) and (max-width: #{$md - 1px}) {
    @content;
  }
}
@mixin lg {
  @media (min-width:$md) and (max-width: #{$lg - 1px}) {
    @content;
  }
}
@mixin xl {
  @media (min-width:$lg) and (max-width: #{$xl - 1px}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: $xxl) {
    @content;
  }
}
