@import "./colors";
@import "./typography";
@import "./mixins";
@import "../fonts/icomoon/style.css";

/*------------------------------------------------------------*\
# DEFAULT LAYOUT STYLES
\*------------------------------------------------------------*/

body,
html {
  font-family: $font-family-base;
  color: $color-text-default;
  font-size: $font-size-default;
  --antd-wave-shadow-color: $color-jade-green;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*----------------| Positioning Styles |----------------*/
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.v-top {
  vertical-align: top;
}
/*------------------------------------------------------------*\
# GENERIC COMPONENT STYLES
\*------------------------------------------------------------*/

.button-group {
  button {
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
button {
  &.fw {
    min-width: 220px;
  }
}

.ant-btn-primary {
  background-color: $color-shopana-default !important;
  border-color: $color-shopana-default !important;
}

.divider-5 {
  margin: 8px 0px;
}

.text-light {
  color: $color-text-light;
}

.panel--white {
  background-color: $color-white;
  padding: 30px;
  border: 1px solid $color-border-default;
  border-radius: 3px;
  margin-bottom: 30px;
}

.rating--small {
  font-size: 14px;
}
.spin-center {
  min-height: 500px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.action-panel {
  button {
    width: 180px;
  }
}
ul,
ol,
dl {
  list-style: none;
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.hide {
  display: none;
}
.modal-from-top {
  top: 0;
}

button:disabled {
  opacity: 0.5;
}

.no-wrap {
  white-space: nowrap;
}
.page-content {
  .page-title {
    margin-bottom: 50px;
  }
}
@include tablet {
  .m-fit {
    position: fixed !important;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 60px;
  }
}
@include phone {
  .m-fit {
    position: fixed !important;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 60px;
  }
}
