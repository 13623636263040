.product-card-container {
    width: 150px;
    margin: auto;
}

.tab-cursor {
    cursor: pointer;
}

.color-red {
    color:#ff4c3b !important;
    border-color: #ff4c3b !important;
}

.product-card {
    width: 95%;
    margin: 5px auto !important;
    padding: 6%;
    .image-container {
        position: relative;
        .front {
            margin: auto;
            max-width: 140px;
            height: 95px;
            .product-image {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
                display: block;
            }
        }
    }
    .product-detail {
        padding: 4% 2%;
        .food-icon {
            width: 12px;
            height: 12px;
            margin-top: 3px;
        }
        .price-tag {
            font-size: 8px;
        }
    }
    .cart-button-mobile {
        display: none;
    }
    &:hover {
        .image-container {
            position: relative;
            .actions {
                display: flex !important;
            }
        }
    }
}

button:focus {
    outline: none !important;
}

.highlight-tab {
    border-bottom: 3px solid #ff4c3b;
    padding-bottom: 5px;
}

.theme-card {
    overflow: hidden;
}

.title-slider {
    .react-multi-carousel-item {
        margin: 4px 3px 4px 1px;
    }
    .slider-button {
        width: 98% !important;
        margin: 0px !important;
    }
}

.search-input {
    background: #fefefe;
}

@media only screen and (max-width: 600px) {
    .actions {
        display: flex !important;
    }
    .cart-button {
        display: none !important;
    }
    .cart-button-mobile {
        display: block !important;
        border: 1px solid #f44336 !important;
        border-radius: 17px !important;
        padding: 2px 10px 2px 10px !important;
    }
    .MuiContainer-root {
        padding-left: 0 !important; 
        padding-right: 0 !important; 
    }
  }
