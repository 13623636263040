.category-card {
    margin: 1rem;
    position: relative;
    box-sizing: border-box;
    .category-image-container {
        padding: 2%;
        border-radius: 2px;
        width: 300px;
        height: 100px;
        .category-image {
            height: 100%;
            width: 100%;
        }
    }
    .category-detail {
        padding: 4%;
        position: absolute;
        top: 0;
        left: 0;
        transition: top 500ms, left 500ms;
    }
    &:hover {
        .category-image-container {
            opacity: 0.5;
        }
        .category-detail {
            top: 25%;
            left: 29%;
            font-weight: 900;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.overflow-clear {
    position: static;
}

.view-icon-right {
    position: absolute !important;
    top: 0;
    right: 0;
}

.view-icon-left {
    position: absolute !important;
    top: 0;
    right: 27px;
}

@media only screen and (max-width: 600px) {
    .category-card {
        margin: 1rem;
        position: relative;
        box-sizing: border-box;
        .category-image-container {
            padding: 2%;
            border-radius: 2px;
            width: 300px;
            height: 100px;
            opacity: 0.5;
            .category-image {
                height: 100%;
                width: 100%;
            }
        }
        .category-detail {
            padding: 4%;
            position: absolute;
            top: 25%;
            left: 29%;
            font-weight: 900;
        }
    }
}