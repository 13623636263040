@import "styles/_colors";
.page-checkout {
  .split-row {
    display: flex;
    justify-content: space-between;
    .value {
      font-weight: bold;
      color: $color-text-medium;
    }
    &.total {
      .value {
        font-size: 18px;
      }
    }
    &.savings {
      .value {
        font-size: 18px;
        color: $color-jade-green;
      }
    }
  }
  .coupon-code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .payment {
    background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
  }
}
