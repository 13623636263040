@import "styles/_colors";
.page-content {
  .page-title {
    margin-bottom: 10px !important;
  }
  margin-top: 50px;
  padding: 10px;
  padding-top: 10px;
  height: calc(100% - 80px);
  //overflow: auto;
  &.no-padding {
    padding: 0px;
    // border: 1px solid;
    height: calc(100vh - 170px);
    // height: auto;
  }
}

.align-left-Last:last-child {
  margin-left: 10px !important;
}

.image_large {
  margin: auto;
  width: 200px !important;
  height: 200px !important;
}

.border-none {
  border: none !important;
}
